<template>
    <div class="wrapper">
        <CRow>
            <CCol lg="12">
                 <CCard>
                    <CCardHeader>
                        <font-awesome-icon icon="pencil-alt"/> Create New Field
                        <a-popover title="NOTE:" placement="left">
                            <template slot="content">
                               <FieldHelp/>
                            </template>
                            <font-awesome-icon 
                                style="color: #4caf50; float: right;" 
                                icon="question-circle" 
                                size="lg"
                            />
                        </a-popover>
                    </CCardHeader>
                    <CCardBody>
                        <FieldCreateForm/>
                    </CCardBody>
                 </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
import FieldCreateForm from './components/FieldCreateForm.vue'
import FieldHelp from '../components/FieldHelp.vue';
export default {
    components: {
        FieldCreateForm,
        FieldHelp,
    },
    name: 'FieldCreate',
    created() {
        this.$emit('activeTab', 1);
    },
    
}
</script>